$( document ).ready(function() {
    $("body").removeClass("trPreload");
    setModalListener();
});

function setModalListener() {
    $('[data-modal]').each(function () {
        this.addEventListener('click', function (event) {
            event.preventDefault();
            var modalAttr = $(this).attr("data-modal");
            openModal(modalAttr);
        });
    });
}

var currentModal = null;

function openModal(modalId) {
    var body = document.getElementsByTagName('body')[0];
    if (currentModal != null) {
        closeModal(currentModal, body);
    }
    var modal = document.getElementById(modalId);
    currentModal = modal;
    modal.style.display = "block";

    body.style.overflow='hidden';

    var span = modal.getElementsByClassName("close")[0];

    span.onclick = function() {
        closeModal(modal,body);
    };

    window.onclick = function(event) {
        if (event.target == modal) {
            closeModal(modal,body);
        }
    }
}

function closeModal(modal,body) {
    modal.style.display = "none";
    body.style.overflow='auto';
    currentModal = null;
}

function scrollToId(id) {
    $('html,body').animate({
        scrollTop: $("#"+id).offset().top
    });
}
